/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Seneste meddelelser i appen',
    titleMeta: 'Viser seneste notifikationer i appen CamAPS FX',
    descriptionMeta: 'Her er teksten til de seneste meddelelser i appen vedrørende appopdateringer, sikkerhedsmeddelelser, brugermanualopdateringer',
    keywords: 'meddelelser' ,
    localeMeta: 'da_dk',

    heading: 'Seneste meddelelser i appen',
    subheading: '',

    header_36:'En ny app-version 06/01/2025',
    body_36:'Kære mylife CamAPS FX-bruger, En ny app-version vil være klar inden for de næste 48 timer. For at vælge din foretrukne opdateringstid skal du deaktivere automatisk opdatering for CamAPS FX i Google Play Butik. Hvis du har spørgsmål, kontakt venligst Ypsomeds kundeservice.',
	
	header_35:'OS 8 & 9 29/12/2024',
    body_35:'Af sikkerhedsmæssige årsager vil mylife CamAPS FX fra den 1. januar 2025 kun blive understøttet på sensor-kompatible telefoner med Android-operativsystemer 10 og derover. Brugere af operativsystemer under OS 10 vil ikke længere kunne opdatere eller geninstallere mylife CamAPS FX efter denne dato. Påmindelse, systemet er endnu ikke kompatibelt med OS15',
	
	header_34:'',
    body_34:'',
	
	header_33:'Besked om planlagt vedligeholdelse 05/12/2024',
    body_33:'På grund af planlagt vedligeholdelse af CamAPS FX Service cloud, vil Companion-funktionen ikke være tilgængelig den 7. december fra kl. 10:00 GMT i cirka tre timer. Vi undskylder for eventuelle ulemper, dette måtte medføre.',
	
	header_32:'Brugermanual opdatering 28/11/2024',
    body_32:'En opdateret version af brugervejledningen er tilgængelig.',
	
	header_31:'En ny app-version 17/11/2024',
    body_31:'Kære mylife CamAPS FX-bruger, En ny app-version vil være klar inden for de næste 48 timer. For at vælge din foretrukne opdateringstid skal du deaktivere automatisk opdatering for CamAPS FX i Google Play Butik. Hvis du har spørgsmål, kontakt venligst Ypsomeds kundeservice.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Af sikkerhedsmæssige årsager vil mylife CamAPS FX fra den 1. januar 2025 kun blive understøttet på sensor-kompatible telefoner med Android-operativsystemer 10 og derover. Brugere af operativsystemer under OS 10 vil ikke længere kunne opdatere eller geninstallere mylife CamAPS FX efter denne dato.',
	
	header_29:'En ny app-version 09/10/2024',
    body_29:'Kære mylife CamAPS FX-bruger, En ny app-version vil være klar inden for de næste 48 timer. For at vælge din foretrukne opdateringstid skal du deaktivere automatisk opdatering for CamAPS FX i Google Play Butik. Hvis du har spørgsmål, kontakt venligst Ypsomeds kundeservice.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Af sikkerhedsmæssige årsager vil mylife CamAPS FX fra den 1. januar 2025 kun blive understøttet på sensor-kompatible telefoner med Android-operativsystemer 10 og derover. Brugere af operativsystemer under OS 10 vil ikke længere kunne opdatere eller geninstallere mylife CamAPS FX efter denne dato.',
	
	header_27:'',
    body_27:'',
	
	header_26:'Brugermanual opdatering 11/05/2024',
    body_26:'En opdateret version af brugervejledningen er tilgængelig.',
	
	header_25:'',
    body_25:'',
	
	header_24:'',
    body_24:'',
	
	header_23:'En ny app-version 24/04/2024',
    body_23:'Kære mylife CamAPS FX-bruger, En ny app-version vil være klar inden for de næste 24 timer. For at vælge din foretrukne opdateringstid skal du deaktivere automatisk opdatering for CamAPS FX i Google Play Butik. Hvis du har spørgsmål, kontakt venligst Ypsomeds kundeservice.',
	
	header_22:'Brugermanual opdatering 21/04/2024',
    body_22:'En opdateret version af brugervejledningen er tilgængelig.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',

	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Brugermanual opdatering 04/03/2024',
    body_16:'En opdateret version af brugervejledningen er tilgængelig.',
	
	header_15:'Brugermanual opdatering 04/01/2024',
    body_15:'En opdateret version af brugervejledningen er tilgængelig.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Påmindelse: For at sikre uafbrudt brug af mylife CamAPS FX-applikationen, bedes du kontrollere producentens sensorwebside for at verificere kompatibiliteten, inden du opdaterer til Android OS 14. Bemærk: Automatiske opdateringer kan være aktiveret som standard i din telefons indstillinger.',
	
	header_13:'VIGTIG PRODUKTINFORMATION  14/11/2023',
    body_13:'VIGTIG PRODUKTINFORMATION',
    body_13_link_text:'Læs venligst og bekræft læsningen af vigtig meddelelse',
    body_13_end: '. Gå til appmenu -> Sikkerhedsmeddelelser.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+final+-+DK+v2.pdf',

	header_12:'Brugermanual opdatering 10/11/2023',
    body_12:'En opdateret version af brugervejledningen er tilgængelig.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'For at sikre uafbrudt brug af mylife CamAPS FX-applikationen, bedes du kontrollere producentens sensorwebside for at verificere kompatibiliteten, inden du opdaterer til Android OS 14. Bemærk: Automatiske opdateringer kan være aktiveret som standard i din telefons indstillinger.Sørg for, at du kører mylife CamAPS FX app version 1.4(173), før du opdaterer til Android OS 14.',
	
	header_10:'VIGTIG PRODUKTINFORMATION  15/08/2023',
    body_10:'VIGTIG PRODUKTINFORMATION Læs venligst og bekræft læsningen af vigtig meddelelse. Gå til appmenu -> Sikkerhedsmeddelelser.',

	header_9:'Brugermanual opdatering 27/07/2023',
    body_9:'En opdateret version af brugervejledningen er tilgængelig.',

	header_8:'Glooko Kompatibilitet 21/07/2023',
    body_8:'Som tidligere annonceret vil diasend blive afbrudt den 28. juli. Hvis du ønsker at fortsætte med at dele data med dit diabetes-team, bedes du opgradere din diasend-konto til Glooko eller oprette en ny Glooko-konto. Opdater til den nyeste mylife CamAPS FX-version, indtast dit nye Glooko-brugernavn og adgangskode i delingsmenuen og genstart din smartphone. Diasend vil fortsat være tilgængelig til visning af historiske data resten af året',
	
	header_7:'Samsung A-serien 14/07/2023',
    body_7:'Brugere af Samsung A-serien modeller (f.eks. A53, A33) kan have oplevet signaltab til deres CGM-sensor efter den seneste Samsung One UI 5.1-opdatering. Samsung har identificeret problemet, og en løsning er under udvikling. Samsung planlægger at frigive en opdatering til One UI den 24. juli (afhængigt af dit område), som vil løse problemet med signaltab. Det anbefales at installere opdateringen, så snart den bliver tilgængelig. Hvis du endnu ikke har opdateret til One UI 5.1, anbefaler vi at vente på den næste Samsung One UI-opdateringen.',

    header_6:'Glooko Kompatibilitet (Ypsomed) 14/06/2023',
    body_6:'mylife CamAPS FX er kompatibel med Glooko. Opdater venligst din mylife CamAPS FX-app til version 1.4(172). Hvis du deler data med en klinik, vil du blive underrettet via din eksisterende diasend-konto, når din klinik opgraderer til Glooko. Når opgraderingen er fuldført, indtast dit nye Glooko-brugernavn og adgangskode i delingsmenuen og genstart din smartphone. Opgrader venligst til Glooko inden den 28. juli 2023. Diasend vil være tilgængelig til at se historiske data resten af året.',

    header_5:'',
    body_5:'',

    header_4:'Brugermanual opdatering 27/05/2023',
    body_4:'En opdateret version af brugervejledningen er tilgængelig.',

    header_3:'SMS-advarsler (Kun Ypsopump) 11/05/2023',
    body_3:'Med den kommende opdatering 1.4(171) i midten af maj vil du kunne aktivere op til 5 SMS-følgere OG samtidig bruge Companion-tilstand. For at bruge Companion-tilstand skal brugerens telefon have en internetforbindelse til at dele data. Brugere skal have et SIM-kort for at tillade, at SMS kan sendes fra deres telefon. Hvis du bruger en af disse muligheder, anbefaler vi, at du kontrollerer, om din mobil abonnement inkluderer SMS og/eller har datadækning afhængigt af den anvendte mulighed.',

    header_2:'Cybersikkerhedsopdatering (Kun Ypsopump) 24/04/2023',
    body_2:'Sikkerheden i mylife CamAPS FX-appen er af største vigtighed. Fra midten af maj vil der være en Google Play Store-forceret cybersikkerhedsopdatering. Efter denne opdatering kan en lille del af telefonerne muligvis ikke klare sig i forbindelse med kontrollen af cybersikkerhedsintegritet. Du kan teste din telefon på forhånd ved at gå til hjælpemenuen og udfylde kontrollen. Hvis din telefon ikke består denne kontrol, kan du prøve at afinstallere og geninstallere appen.',

    header_1:'',
    body_1:'',
};
