 /**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Neueste In-App-Benachrichtigungen',
    titleMeta: 'Zeigt aktuelle In-App-Benachrichtigungen von CamAPS FX',
    descriptionMeta: 'Hier finden Sie den Text der letzten In-App-Benachrichtigungen zu App-Updates, Sicherheitshinweisen und Benutzerhandbuch-Updates',
    keywords: 'Benachrichtigungen' ,
    localeMeta: 'de_de',

    heading: 'Neueste In-App-Benachrichtigungen',
    subheading: '',

     header_36:'Neue App-Version 06/01/2025',
    body_36:'Lieber mylife CamAPS FX Nutzer, eine neue App-Version wird in den nächsten 48 Stunden verfügbar sein. Um Ihren bevorzugten Update-Zeitpunkt zu wählen, deaktivieren Sie die automatische Aktualisierung für CamAPS FX im Google Play Store. Bei Fragen wenden Sie sich bitte an den Kundenservice von Ypsomed.',
		
	header_35:'OS 8 & 9 29/12/2024',
    body_35:'Aus Gründen der Cybersicherheit wird ab dem 1. Januar 2025 CamAPS FX nur noch auf sensor-kompatiblen Handys mit Android-Betriebssystemen 10 und höher unterstützt. Benutzer von Betriebssystemen unter OS 10 werden CamAPS FX nach diesem Datum nicht mehr aktualisieren oder neu installieren können. Erinnerung, das System ist noch nicht OS15-kompatibe',
	
	header_34:'',
    body_34:'',
	
	header_33:'Hinweis zur geplanten Wartung 05/12/2024',
    body_33:'Aufgrund geplanter Wartungsarbeiten an der CamAPS FX Service-Cloud wird die Companion-Funktion am 7. Dezember voraussichtlich ab 11:00 Uhr für etwa drei Stunden nicht verfügbar sein. Wir entschuldigen uns für etwaige Unannehmlichkeiten, die dadurch entstehen könnten..',
	
	header_32:'Aktualisierung des Benutzerhandbuchs 28/11/2024',
    body_32:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_31:'Neue App-Version 17/11/2024',
    body_31:'Lieber mylife CamAPS FX Nutzer, eine neue App-Version wird in den nächsten 48 Stunden verfügbar sein. Um Ihren bevorzugten Update-Zeitpunkt zu wählen, deaktivieren Sie die automatische Aktualisierung für CamAPS FX im Google Play Store. Bei Fragen wenden Sie sich bitte an den Kundenservice von Ypsomed.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Aus Gründen der Cybersicherheit wird ab dem 1. Januar 2025 CamAPS FX nur noch auf sensor-kompatiblen Handys mit Android-Betriebssystemen 10 und höher unterstützt. Benutzer von Betriebssystemen unter OS 10 werden CamAPS FX nach diesem Datum nicht mehr aktualisieren oder neu installieren können.',
	
	header_29:'Neue App-Version 09/10/2024',
    body_29:'Lieber mylife CamAPS FX Nutzer, eine neue App-Version wird in den nächsten 48 Stunden verfügbar sein. Um Ihren bevorzugten Update-Zeitpunkt zu wählen, deaktivieren Sie die automatische Aktualisierung für CamAPS FX im Google Play Store. Bei Fragen wenden Sie sich bitte an den Kundenservice von Ypsomed.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Aus Gründen der Cybersicherheit wird ab dem 1. Januar 2025 CamAPS FX nur noch auf sensor-kompatiblen Handys mit Android-Betriebssystemen 10 und höher unterstützt. Benutzer von Betriebssystemen unter OS 10 werden CamAPS FX nach diesem Datum nicht mehr aktualisieren oder neu installieren können.',
	
	header_27:'',
    body_27:'',
	
	header_26:'Aktualisierung des Benutzerhandbuchs 11/05/2024',
    body_26:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_25:'Neue App-Version 05/05/2024',
    body_25:'Lieber mylife CamAPS FX Nutzer, eine neue App-Version wird in den nächsten 24 Stunden verfügbar sein. Um Ihren bevorzugten Update-Zeitpunkt zu wählen, deaktivieren Sie die automatische Aktualisierung für CamAPS FX im Google Play Store. Bei Fragen wenden Sie sich bitte an den Kundenservice von Ypsomed.',
	
	header_24:'',
    body_24:'',
	
	header_23:'Neue App-Version 24/04/2024',
    body_23:'Lieber mylife CamAPS FX Nutzer, eine neue App-Version wird in den nächsten 24 Stunden verfügbar sein. Um Ihren bevorzugten Update-Zeitpunkt zu wählen, deaktivieren Sie die automatische Aktualisierung für CamAPS FX im Google Play Store. Bei Fragen wenden Sie sich bitte an den Kundenservice von Ypsomed.',
	
	header_22:'Aktualisierung des Benutzerhandbuchs 21/04/2024',
    body_22:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',

	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',
	 
    header_17:'',
    body_17:'',

    header_16:'Aktualisierung des Benutzerhandbuchs 04/03/2024',
    body_16:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_15:'Aktualisierung des Benutzerhandbuchs 04/01/2024',
    body_15:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_14:'Android OS 14 22/11/2023',
    body_14:'Erinnerung: Überprüfen Sie bitte die Kompatibilität Ihres Smartphones und Betriebssystems auf der Website des Sensor-Herstellers, bevor Sie auf Android OS 14 updaten, um eine ununterbrochene Nutzung von mylife CamAPS FX sicherzustellen. Hinweis: Automatische Updates sind möglicherweise standardmäßig in den Einstellungen Ihres Telefons aktiviert.',
	
	header_13:'SICHERHEITSHINWEIS IM FELD 14/11/2023',
    body_13:'',
    body_13_link_text:'SICHERHEITSHINWEIS IM FELD',
    body_13_end: '. Zum Lesen gehen Sie bitte zum App-Menü -> Sicherheitsbenachrichtigungen und öffnen Sie den Link zur Sicherheitsbenachrichtigung.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+-+DE.pdf',

	header_12:'Aktualisierung des Benutzerhandbuchs 10/11/2023',
    body_12:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_11:'Android OS 14 12/09/2023',
    body_11:'Überprüfen Sie bitte die Kompatibilität Ihres Smartphones und Betriebssystems auf der Website des Sensor-Herstellers, bevor Sie auf Android OS 14 updaten, um eine ununterbrochene Nutzung von mylife CamAPS FX sicherzustellen. Hinweis: Automatische Updates sind möglicherweise standardmäßig in den Einstellungen Ihres Telefons aktiviert. Bitte stellen Sie sicher, dass Sie die mylife CamAPS FX App Version 1.4(173) verwenden, bevor Sie auf Android OS 14 aktualisieren.',
	
	header_10:'SICHERHEITSHINWEIS IM FELD 15/08/2023',
    body_10:'SICHERHEITSHINWEIS IM FELD. Zum Lesen gehen Sie bitte zum App-Menü -> Sicherheitsbenachrichtigungen und öffnen Sie den Link zur Sicherheitsbenachrichtigung.',
	
	header_9:'Aktualisierung des Benutzerhandbuchs 27/07/2023',
    body_9:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',
	
	header_8:'Kompatibilität mit Glooko 21/07/2023',
    body_8:'Wie bereits angekündigt, wird diasend am 28. Juli eingestellt. Wenn Sie Ihre Daten weiterhin mit Ihrem Diabetesteam teilen möchten, aktualisieren Sie bitte Ihr diasend-Konto auf Glooko oder erstellen Sie ein neues Glooko-Konto. Bitte aktualisieren Sie auf die neueste mylife CamAPS FX-Version, geben Sie Ihren neuen Glooko-Benutzernamen und Ihr Passwort im Share-Menü ein und starten Sie Ihr Smartphone neu. Diasend bleibt für den Rest des Jahres für die Anzeige historischer Daten verfügbar.',
	
	header_7:'Samsung A-Serie 14/07/2023',
    body_7:'Benutzer von Samsung A-Serie Modellen (z.B. A53, A33) könnten nach dem neuesten Samsung One UI 5.1 Update Signalverluste zu ihrem CGM-Sensor festgestellt haben. Samsung hat das Problem identifiziert und arbeitet an einer Lösung. Samsung plant, am 24. Juli (abhängig von Ihrer Region) ein Update für One UI zu veröffentlichen, das das Problem beheben wird. Es wird empfohlen, das Update so bald wie möglich zu installieren, sobald es verfügbar ist. Sollten Sie das Update auf One UI 5.1 noch nicht gemacht haben empfiehlt sich auf das nächste Samsung One UI Update zu warten.',

    header_6:'',
    body_6:'',

    header_5:'Kompatibilität mit Glooko 21/06/2023',
    body_5:'CamAPS FX ist kompatibel mit Glooko. Bitte aktualisieren Sie Ihre CamAPS FX App auf 1.4(172). Wenn Sie Daten mit einer Klinik teilen, werden Sie in Ihrem bestehenden diasend-Konto benachrichtigt, wenn Ihre Klinik ein Upgrade auf Glooko durchführt. Sobald das Upgrade abgeschlossen ist, geben Sie Ihren neuen Glooko-Benutzernamen und Ihr Passwort in das Freigabemenü ein und starten Sie Ihr Smartphone neu. Bitte führen Sie das Upgrade auf Glooko vor dem 28. Juli 2023 durch. Diasend wird für den Rest des Jahres verfügbar sein, um historische Daten einzusehen.',

    header_4:'Aktualisierung des Benutzerhandbuchs 27/05/2023',
    body_4:'Eine aktualisierte Version des Benutzerhandbuchs ist verfügbar.',

    header_3:'SMS-Warnungen (Nur Ypsopumpe) 11/05/2023',
    body_3:'Mit dem bevorstehenden Update 1.4(171) Mitte Mai werden Sie bis zu 5 SMS-Follower aktivieren UND gleichzeitig den Companion-Modus verwenden können. Um den Companion-Modus zu nutzen und Daten teilen zu können, muss das Telefon des Benutzers eine Internetverbindung haben. Benutzer müssen eine SIM-Karte haben, um das Senden von SMS von ihrem Telefon zu ermöglichen. Wenn Sie eine dieser Optionen verwenden, empfehlen wir Ihnen, Ihren Mobilfunktarif zu überprüfen, ob SMS und/oder mobile Daten je nach verwendeter Option enthalten sind.',

    header_2:'Cyber-Sicherheits-Update (Nur Ypsopumpe) 24/04/2023',
    body_2:'Hinweis: Für Nutzer aus Österreich nicht zutreffend. Die Sicherheit der mylife CamAPS FX App hat höchste Priorität. Ab Mitte Mai wird ein von Google Play Store durchgesetztes Cyber-Sicherheits-Update durchgeführt. Nach diesem Update könnte bei einem geringen Anteil der genutzten Smartphones der Cyber-Sicherheitstest nicht bestanden werden. Sie können Ihr Telefon im Voraus testen, indem Sie in der App das Hilfe-Menü aufrufen und die „Integritätsprüfung“ durchführen. Wenn Ihr Telefon bei diesem Test durchfällt  Versuchen, die App zu deinstallieren und neu zu installieren.',

    header_1:'Kompatibilität mit Glooko 14/04/2023',
    body_1:'mylife CamAPS FX ist jetzt kompatibel mit Glooko. Bitte aktualisieren Sie Ihre mylife CamAPS FX-App. Melden Sie sich auf einem Webbrowser bei Ihrem Diasend-Konto an und folgen Sie dem Upgrade-Prozess, da Diasend mit Glooko fusioniert. Nach erfolgreicher Fertigstellung starten Sie bitte Ihr Telefon neu, um Ihre Daten automatisch an Glooko zu senden. Diasend wird für den Rest des Jahres verfügbar sein, um bisherige Daten einzusehen. Bitte aktualisieren Sie vor dem 14. Juni 2023 auf Glooko, wenn Sie Diasend nutzen. Hinweis: Wenn Sie während des Upgrade-Vorgangs Ihr Passwort ändern, müssen Sie das Passwort auch in mylife CamAPS FX im Menü „Teilen“ aktualisieren.',
};
