import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import mobile_logo from '../assets/images/mobile-logo.svg';
import {headerText as headerText_en} from "../libs/en/texts-header-lib";
import {headerText as headerText_de} from "../libs/de/texts-header-lib";
import {headerText as headerText_fr} from "../libs/fr/texts-header-lib";
import {headerText as headerText_fr_ca} from "../libs/fr-ca/texts-header-lib";
import {headerText as headerText_nl} from "../libs/nl/texts-header-lib";
import {headerText as headerText_it} from "../libs/it/texts-header-lib";
import {headerText as headerText_cz} from "../libs/cz/texts-header-lib";
import {headerText as headerText_fi} from "../libs/fi/texts-header-lib";
import {headerText as headerText_pl} from "../libs/pl/texts-header-lib";
import {headerText as headerText_se} from "../libs/se/texts-header-lib";
import {headerText as headerText_no} from "../libs/no/texts-header-lib";
import {headerText as headerText_dk} from "../libs/dk/texts-header-lib";
import {headerText as headerText_es} from "../libs/es/texts-header-lib";
import parse from "html-react-parser";

/**
 * Whether made available for Canada
 * @type {boolean}
 */
const isPublishedInCanada = true;

export class Header extends Component {
    container = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.props.language,
            clickedOutside: false,
            faq: false,
            support: false,
            history: false,
            open: false,
            about: false,
            isCookie: (localStorage.getItem('camdiab-cookie-flag') === "true") || false,
            isCookieDenied: (localStorage.getItem('camdiab-cookie-denied') === "true") || false,
            headerText: headerText_en,
            isLanguageChanging: false,
        };
        if (this.state.language === "DE") {
            this.state.headerText = headerText_de;
        } if (this.state.language === "EN-CA") {
            this.state.headerText = headerText_en;
        } else if ((this.state.language === "FR") || (this.state.language === "FR-FR")){
            this.state.headerText = headerText_fr;
        } if (this.state.language === "FR-CA") {
            this.state.headerText = headerText_fr_ca;
        } else if (this.state.language === "NL") {
            this.state.headerText = headerText_nl;
        } else if (this.state.language === "IT") {
            this.state.headerText = headerText_it;
        } else if (this.state.language === "CZ") {
            this.state.headerText = headerText_cz;
        } else if (this.state.language === "FI") {
            this.state.headerText = headerText_fi;
        } else if (this.state.language === "PL") {
            this.state.headerText = headerText_pl;
        } else if (this.state.language === "SE") {
            this.state.headerText = headerText_se;
        } else if (this.state.language === "NO") {
            this.state.headerText = headerText_no;
        } else if (this.state.language === "DK") {
            this.state.headerText = headerText_dk;
        } else if (this.state.language === "ES") {
            this.state.headerText = headerText_es;
        }
    }

    componentDidMount() {

        if (localStorage.getItem('camdiab-cookie-flag') === "true") {
            this.setState({isCookie: true})
        } else {
            localStorage.setItem('camdiab-cookie-denied', "true")
        }

        if (localStorage.getItem('camdiab-cookie-denied') === "true") {
            this.setState({isCookieDenied: true})
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        document.body.classList.remove('open-language');
        setTimeout(function () {
            document.body.classList.remove('open-toggle');
        }, 10);
        // const el = document.querySelector('.menu-icon');
        // el.onclick = () => {
        // 	document.body.classList.toggle('open-toggle');
        // };
        // Get the Link Class//

        document
            .querySelectorAll('.site-navigation nav ul li a')
            .forEach((item, index) => {
                //console.log(item.textContent);
                item.parentNode.classList.remove('active');
                if (item.getAttribute('href') === window.location.pathname) {
                    item.parentNode.classList.add('active');
                }
            });
    }

    handleClickOutside = (event) => {
        if (
            this.container.current &&
            !this.container.current.contains(event.target)
        ) {
            this.setState({
                open: false,
                about: false,
            });
            document.body.classList.remove('open-language');
        }
    };
    handleButtonClick = () => {
        this.setState((state) => {
            return {
                open: !state.open,
            };
        });
        document.body.classList.toggle('open-language');
    };
    // toogling = (e) => {

    // }


    removeToogling = () => {
        setTimeout(() => {
            document.body.classList.remove('open-toggle');
        }, 10);
        this.setState({about: false});
    };

    handleClickInside = () => {
        this.setState({clickedOutside: false});
    };

    getLanguage = () => {
        const langCode = this.props.props.language;
        let languageFull = "English";
        if (langCode === "EN-CA") {
            languageFull = "English (Canada)"
        } else if (langCode === "DE") {
            languageFull = "Deutsch"
        } else if (langCode === "FR") {
            languageFull = "Français (Suisse)"
        } else if (langCode === "FR-CA") {
            languageFull = "Français (Canada)"
        } else if (langCode === "FR-FR") {
            languageFull = "Français (France)"
        } else if (langCode === "NL") {
            languageFull = "Nederlands"
        } else if (langCode === "IT") {
            languageFull = "Italiano"
        } else if (langCode === "CZ") {
            languageFull = "Česky"
        } else if (langCode === "FI") {
            languageFull = "Suomi"
        } else if (langCode === "PL") {
            languageFull = "Polski"
        } else if (langCode === "SE") {
            languageFull = "Svenska"
        } else if (langCode === "NO") {
            languageFull = "Norsk"
        } else if (langCode === "DK") {
            languageFull = "Dansk"
        } else if (langCode === "ES") {
            languageFull = "Español"
        }
        return languageFull
    };

    getLanguagePrefix = () => {
        const langCode = this.props.props.language;
        let prefix = '';
        if (langCode === "DE") {
            prefix = '/de'
        } else if (langCode === "EN-CA") {
            prefix = '/en-ca'
        } else if (langCode === "FR") {
            prefix = '/fr-ch'
        } else if (langCode === "FR-CA") {
            prefix = '/fr-ca'
        } else if (langCode === "FR-FR") {
            prefix = '/fr'
        } else if (langCode === "NL") {
            prefix = '/nl'
        } else if (langCode === "IT") {
            prefix = '/it'
        } else if (langCode === "CZ") {
            prefix = '/cz'
        } else if (langCode === "FI") {
            prefix = '/fi'
        } else if (langCode === "PL") {
            prefix = '/pl'
        } else if (langCode === "SE") {
            prefix = '/se'
        } else if (langCode === "NO") {
            prefix = '/nb'
        } else if (langCode === "DK") {
            prefix = '/dk'
        } else if (langCode === "ES") {
            prefix = '/es'
        }
        return prefix
    };

    pathPerLanguage = (path, languageCode) => {
        path = path.toLowerCase().replace(/^(\/de)/, "");
        path = path.toLowerCase().replace(/^(\/en-ca)/, "");
        path = path.toLowerCase().replace(/^(\/fr-ch)/, "");
        path = path.toLowerCase().replace(/^(\/fr-ca)/, "");
        path = path.toLowerCase().replace(/^(\/fr)/, "");
        path = path.toLowerCase().replace(/^(\/nl)/, "");
        path = path.toLowerCase().replace(/^(\/it)/, "");
        path = path.toLowerCase().replace(/^(\/cz)/, "");
        path = path.toLowerCase().replace(/^(\/fi)/, "");
        path = path.toLowerCase().replace(/^(\/pl)/, "");
        path = path.toLowerCase().replace(/^(\/se)/, "");
        path = path.toLowerCase().replace(/^(\/nb)/, "");
        path = path.toLowerCase().replace(/^(\/dk)/, "");
        path = path.toLowerCase().replace(/^(\/es)/, "");

        if (languageCode === 'DE') {
            path = '/de' + path;
        } else if (languageCode === 'EN-CA') {
            path = '/en-ca' + path;
        } else if (languageCode === 'FR') {
            path = '/fr-ch' + path;
        } else if (languageCode === 'FR-CA') {
            path = '/fr-ca' + path;
        } else if (languageCode === 'FR-FR') {
            path = '/fr' + path;
        } else if (languageCode === 'NL') {
            path = '/nl' + path;
        } else if (languageCode === 'IT') {
            path = '/it' + path;
        } else if (languageCode === 'CZ') {
            path = '/cz' + path;
        } else if (languageCode === 'FI') {
            path = '/fi' + path;
        } else if (languageCode === 'PL') {
            path = '/pl' + path;
        } else if (languageCode === 'SE') {
            path = '/se' + path;
        } else if (languageCode === 'NO') {
            path = '/nb' + path;
        } else if (languageCode === 'DK') {
            path = '/dk' + path;
        } else if (languageCode === 'ES') {
            path = '/es' + path;
        }
        return path;
    };

    render() {
        return (
            <div>
                <header className='header-main'> {
                    !this.state.isCookie &&
                    <div className="header-top-bar">
                        <div className="container">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <p>{parse(this.state.headerText.cookiesIntroText)}</p>
                                    <p><a href="/#" title="Accept cookies" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({isCookie: true}, () => {
                                            localStorage.setItem('camdiab-cookie-flag', "true");
                                            localStorage.setItem('camdiab-cookie-denied', "false")
                                        })
                                    }}>{parse(this.state.headerText.cookiesAccept)}</a>
                                        <a href="/#" title="Decline cookies" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({isCookie: true}, () => {
                                                localStorage.setItem('camdiab-cookie-flag', "true");
                                                localStorage.setItem('camdiab-cookie-denied', "true")
                                            })
                                        }}>{parse(this.state.headerText.cookiesDeny)}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                    <div className='container-fluid'>
                        <div className='row align-items-center'>
                            <div className='col-12 col-sm-12 header-wrap'>
                                <div className='logo'>
                                    <NavLink to={this.getLanguagePrefix() + '/'}>
                                        <img src={logo} className='App-logo' alt='logo'/>
                                        <img src={mobile_logo} className='mobile-logo' alt='logo'/>
                                    </NavLink>
                                </div>
                                <div className='header-navigation'>
                                    <div
                                        className='menu-icon'
                                        onClick={() =>
                                            document.body.classList.toggle('open-toggle')
                                        }>
                                        <span>
                                        </span>
                                        <span>
                                        </span>
                                        <span>
                                        </span>
                                    </div>
                                    <div className='navigation-wrap'>
                                        <div className='site-navigation'>
                                            <nav>
                                                <ul>
                                                    <li onClick={(e) => this.removeToogling()}>
                                                        <NavLink to={this.getLanguagePrefix() + '/faq'}
                                                                 activeClassName='active'>
                                                            {parse(this.state.headerText.tabFAQs)}
                                                        </NavLink>
                                                    </li>
                                                    <li onClick={(e) => this.removeToogling()}>
                                                        <NavLink to={this.getLanguagePrefix() + '/support'}
                                                                 activeClassName='active'>
                                                            {parse(this.state.headerText.tabSupport)}
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            to={this.getLanguagePrefix() + '/history'}
                                                            onClick={(e) => this.removeToogling()}
                                                            activeClassName='active'>
                                                            {parse(this.state.headerText.tabHistory)}
                                                        </NavLink>
                                                    </li>
                                                    {(this.state.language !== "FR-FR") &&
                                                    <li>
                                                        <NavLink
                                                            to={this.getLanguagePrefix() + '/reviews'}
                                                            activeClassName='active'
                                                            onClick={(e) => this.removeToogling()}>
                                                            {parse(this.state.headerText.tabReviews)}
                                                        </NavLink>
                                                    </li>}
                                                    {/* <li
													 </li> */}
                                                    <li onClick={(e) => this.removeToogling()}>
                                                        <NavLink to={this.getLanguagePrefix() + '/training'}
                                                                 activeClassName='active'>
                                                            {parse(this.state.headerText.tabTraining)}
                                                        </NavLink>
                                                    </li>
                                                    <li onClick={(e) => this.removeToogling()}>
                                                        <NavLink to={this.getLanguagePrefix() + '/notifications'}
                                                                 activeClassName='active'>
                                                            {parse(this.state.headerText.tabNotifications)}
                                                        </NavLink>
                                                    </li>
                                                    <li onClick={(e) => this.removeToogling()}>
                                                        <NavLink to={this.getLanguagePrefix() + '/orders'}
                                                                 activeClassName='active'>
                                                            {parse(this.state.headerText.tabOrders)}
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        {<div className="lang-dropdown" ref={this.container}>
                                            <div className="lang-label">
                                                <input type="checkbox" id="menu-toggle"
                                                       onClick={(e) => this.handleButtonClick(e)}/>
                                                <label htmlFor="menu-toggle">{this.getLanguage()}</label>
                                                {this.state.open && (
                                                    <div className="lang-list">
                                                        <ul>
                                                            {(this.props.props.language !== "EN") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'EN')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('DE');
                                                                        this.setState({
                                                                            headerText: headerText_en,
                                                                        });
                                                                    }}>
                                                                    English
                                                                </NavLink>
                                                            </li>
                                                            }
                                                            {(this.props.props.language !== "EN-CA") && isPublishedInCanada &&
                                                                <li>
                                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                    <NavLink
                                                                        to={this.pathPerLanguage(
                                                                            this.props.props.location.pathname, 'EN-CA')}
                                                                        onClick={(e) => {
                                                                            this.handleButtonClick(e);
                                                                            // this.props.props.setSiteLanguage('DE');
                                                                            this.setState({
                                                                                headerText: headerText_en,
                                                                            });
                                                                        }}>
                                                                        English (Canada)
                                                                    </NavLink>
                                                                </li>
                                                            }

                                                            {(this.props.props.language !== "CZ") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'CZ')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('CZ');
                                                                        this.setState({
                                                                            headerText: headerText_cz,
                                                                        });
                                                                    }}>
                                                                    Česky
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "DK") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'DK')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('dk');
                                                                        this.setState({
                                                                            headerText: headerText_dk,
                                                                        });
                                                                    }}>
                                                                    Dansk
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "DE") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'DE')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('DE');
                                                                        this.setState({
                                                                            headerText: headerText_de,
                                                                        });
                                                                    }}>
                                                                    Deutsch
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "ES") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'ES')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('ES');
                                                                        this.setState({
                                                                            headerText: headerText_es,
                                                                        });
                                                                    }}>
                                                                    Español
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "FR-CA") && isPublishedInCanada &&
                                                                <li>
                                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                    <NavLink
                                                                        to={this.pathPerLanguage(
                                                                            this.props.props.location.pathname, 'FR-CA')}
                                                                        onClick={(e) => {
                                                                            this.handleButtonClick(e);
                                                                            // this.props.props.setSiteLanguage('FR');
                                                                            this.setState({
                                                                                headerText: headerText_fr_ca,
                                                                            });
                                                                        }}>
                                                                        Français (Canada)
                                                                    </NavLink>
                                                                </li>
                                                            }

                                                            {(this.props.props.language !== "FR") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'FR')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('FR');
                                                                        this.setState({
                                                                            headerText: headerText_fr,
                                                                        });
                                                                    }}>
                                                                    Français (Suisse)
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "FR-FR") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'FR-FR')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('FR-FR');
                                                                        this.setState({
                                                                            headerText: headerText_fr,
                                                                        });
                                                                    }}>
                                                                    Français (France)
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "IT") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'IT')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('IT');
                                                                        this.setState({
                                                                            headerText: headerText_it,
                                                                        });
                                                                    }}>
                                                                    Italiano
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "NL") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'NL')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('NL');
                                                                        this.setState({
                                                                            headerText: headerText_nl,
                                                                        });
                                                                    }}>
                                                                    Nederlands
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "NO") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'NO')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('NO');
                                                                        this.setState({
                                                                            headerText: headerText_no,
                                                                        });
                                                                    }}>
                                                                    Norsk
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "PL") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'PL')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('PL');
                                                                        this.setState({
                                                                            headerText: headerText_pl,
                                                                        });
                                                                    }}>
                                                                    Polski
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "FI") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'FI')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('FI');
                                                                        this.setState({
                                                                            headerText: headerText_fi,
                                                                        });
                                                                    }}>
                                                                    Suomi
                                                                </NavLink>
                                                            </li>
                                                            }

                                                            {(this.props.props.language !== "SE") &&
                                                            <li>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <NavLink
                                                                    to={this.pathPerLanguage(
                                                                        this.props.props.location.pathname, 'SE')}
                                                                    onClick={(e) => {
                                                                        this.handleButtonClick(e);
                                                                        // this.props.props.setSiteLanguage('SE');
                                                                        this.setState({
                                                                            headerText: headerText_se,
                                                                        });
                                                                    }}>
                                                                    Svenska
                                                                </NavLink>
                                                            </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}


