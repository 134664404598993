/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Recente in-app meldingen',
    titleMeta: 'Toont recente in-app-meldingen CamAPS FX',
    descriptionMeta: 'Hier is de tekst van recente in-app-meldingen met betrekking tot app-updates, veiligheidsmededelingen, updates van gebruikershandleidingen',
    keywords: 'meldingen' ,
    localeMeta: 'nl_NL',

    heading: 'Recente in-app meldingen',
    subheading: '',

    header_36:'een nieuwe app-versie beschikbaar 06/01/2025',
    body_36:'Geachte mylife CamAPS FX-gebruiker, Binnen de komende 24 uur zal een nieuwe app-versie beschikbaar zijn. Om uw voorkeurstijd voor de update te kiezen, schakelt u de automatische updates voor CamAPS FX uit in de Google Play Store. Als u vragen heeft, neem dan contact op met de klantenservice van Ypsomed.',
	
	header_35:'OS 8 & 9 29/12/2024',
    body_35:'Per 1 januari 2025 worden alleen nog smartphones ondersteund die compatible zijn met besturingssysteem (OS) 10 of hoger en uw CGM sensor. Als uw smartphone niet geschikt is voor een update naar OS10 of hoger, zal u tegen die tijd over moeten stappen op een andere smartphone. OS8 en OS9 zijn verouderd en worden door Android niet langer technisch ondersteund en krijgen ook geen veiligheid updates meer. Herinnering, het systeem is nog niet compatibel met OS15.',
	
	header_34:'',
    body_34:'',
	
	header_33:'Kennisgeving van gepland onderhoud 05/12/2024',
    body_33:'Vanwege geplande onderhoudswerkzaamheden aan de CamAPS FX Service cloud, zal de Companion-functionaliteit op 7 december vanaf 10:00 GMT ongeveer drie uur niet beschikbaar zijn. Onze excuses voor het ongemak dat dit kan veroorzaken.',
	
	header_32:'Gebruikershandleiding bijwerken 28/11/2024',
    body_32:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_31:'een nieuwe app-versie beschikbaar 17/11/2024',
    body_31:'Geachte mylife CamAPS FX-gebruiker, Binnen de komende 24 uur zal een nieuwe app-versie beschikbaar zijn. Om uw voorkeurstijd voor de update te kiezen, schakelt u de automatische updates voor CamAPS FX uit in de Google Play Store. Als u vragen heeft, neem dan contact op met de klantenservice van Ypsomed.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Per 1 januari 2025 worden alleen nog smartphones ondersteund die compatible zijn met besturingssysteem (OS) 10 of hoger en uw CGM sensor. Als uw smartphone niet geschikt is voor een update naar OS10 of hoger, zal u tegen die tijd over moeten stappen op een andere smartphone. OS8 en OS9 zijn verouderd en worden door Android niet langer technisch ondersteund en krijgen ook geen veiligheid updates meer.',
	
	header_29:'een nieuwe app-versie beschikbaar 09/10/2024',
    body_29:'Geachte mylife CamAPS FX-gebruiker, Binnen de komende 24 uur zal een nieuwe app-versie beschikbaar zijn. Om uw voorkeurstijd voor de update te kiezen, schakelt u de automatische updates voor CamAPS FX uit in de Google Play Store. Als u vragen heeft, neem dan contact op met de klantenservice van Ypsomed.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Per 1 januari 2025 worden alleen nog smartphones ondersteund die compatible zijn met besturingssysteem (OS) 10 of hoger en uw CGM sensor. Als uw smartphone niet geschikt is voor een update naar OS10 of hoger, zal u tegen die tijd over moeten stappen op een andere smartphone. OS8 en OS9 zijn verouderd en worden door Android niet langer technisch ondersteund en krijgen ook geen veiligheid updates meer.',
	
	header_27:'',
    body_27:'',
	
	header_26:'Gebruikershandleiding bijwerken 11/05/2024',
    body_26:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_25:'',
    body_25:'',
	
	header_24:'',
    body_24:'',
	
	header_23:'een nieuwe app-versie beschikbaar 24/04/2024',
    body_23:'Geachte mylife CamAPS FX-gebruiker, Binnen de komende 24 uur zal een nieuwe app-versie beschikbaar zijn. Om uw voorkeurstijd voor de update te kiezen, schakelt u de automatische updates voor CamAPS FX uit in de Google Play Store. Als u vragen heeft, neem dan contact op met de klantenservice van Ypsomed.',
	
	header_22:'Gebruikershandleiding bijwerken 21/04/2024',
    body_22:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Gebruikershandleiding bijwerken 04/03/2024',
    body_16:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_15:'Gebruikershandleiding bijwerken 04/01/2024',
    body_15:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Herinnering: Let op: update uw smartphone niet naar Android OS14 zonder eerst de compatibiliteit op de website van de fabrikant van de sensor te controleren. Het niet compatibel zijn, kan van invloed zijn op functioneren van de mylife CamAPS FX app. Let op dat automatische updates standaard ingeschakeld kunnen zijn in de instellingen van uw telefoon.',
	
	header_13:'BERICHT INZAKE DE VEILIGHEID 14/11/2023',
    body_13:'BERICHT INZAKE DE VEILIGHEID ',
    body_13_link_text:'Lees deze veiligheidsinformatie',
    body_13_end: '. Ga naar het app-menu -> Veiligheidsmeldingen.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+final+-+NL.pdf',

	header_12:'Gebruikershandleiding bijwerken 10/11/2023',
    body_12:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_11:'Android OS 14. 13/09/2023',
    body_11:'Let op: update uw smartphone niet naar Android OS14 zonder eerst de compatibiliteit op de website van de fabrikant van de sensor te controleren. Het niet compatibel zijn, kan van invloed zijn op functioneren van de mylife CamAPS FX app. Let op dat automatische updates standaard ingeschakeld kunnen zijn in de instellingen van uw telefoon.Zorg ervoor dat u mylife CamAPS FX-app versie 1.4(173) gebruikt voordat u bijwerkt naar Android OS 14.',
	
	header_10:'BERICHT INZAKE DE VEILIGHEID 15/08/2023',
    body_10:'BERICHT INZAKE DE VEILIGHEID Lees deze veiligheidsinformatie. Ga naar het app-menu -> Veiligheidsmeldingen.',
	
	header_9:'Gebruikershandleiding bijwerken 27/07/2023',
    body_9:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',
	
	header_8:'Glooko-Kompatibilität 21/07/2023)',
    body_8:'Zoals eerder aangekondigd stopt Diasend per 28 juli. Als u uw gegevens wilt blijven delen met uw diabetesteam, kunt u uw Diasend-account upgraden naar Glooko of een nieuw Glooko-account aanmaken. Werk bij naar de nieuwste mylife CamAPS FX-versie, voer uw nieuwe Glooko-gebruikersnaam en wachtwoord in in het share-menu en start uw smartphone opnieuw op. Diasend blijft beschikbaar voor het bekijken van historische gegevens voor de rest van het jaar.',
	
	header_7:'Samsung smartphones uit de A-serie 14/07/2023',
    body_7:'Gebruikers van Samsung smartphones uit de A-serie (bijv. A53, A33) hebben mogelijk signaalverlies ondervonden met de CGM-sensor na de recente Samsung One UI 5.1-update. Samsung heeft het probleem geïdentificeerd en er wordt momenteel aan een oplossing gewerkt. Samsung is van plan een update voor One UI uit te brengen op 24 juli (afhankelijk van je regio), die het probleem van signaalverlies zal oplossen. Het wordt aanbevolen om de update te installeren zodra deze beschikbaar is. Als je nog niet hebt bijgewerkt naar One UI 5.1, raden we aan te wachten op de volgende Samsung One UI-update.',

    header_6:'Glooko-Kompatibilität (Alleen Ypsomed) 29/06/2023',
    body_6:'mylife CamAPS FX is compatibel met Glooko. Update alstublieft uw mylife CamAPS FX-app naar versie 1.4(172). Als u gegevens deelt met een kliniek, wordt u op de hoogte gesteld via uw bestaande diasend-account wanneer uw kliniek overstapt naar Glooko. Zodra de upgrade is voltooid, voert u uw nieuwe Glooko gebruikersnaam en wachtwoord in het deelmenu in en herstart u uw smartphone. Upgrade alstublieft naar Glooko vóór 28 juli 2023. Diasend blijft beschikbaar om historische gegevens te bekijken gedurende de rest van het jaar.',

    header_5:'',
    body_5:'',

    header_4:'Gebruikershandleiding bijwerken 27/05/2023',
    body_4:'Er is een bijgewerkte versie van de gebruikershandleiding beschikbaar.',

    header_3:'SMS-waarschuwingen (Alleen Ypsopump) 11/05/2023',
    body_3:'Met de aanstaande update 1.4(171) medio mei, kunt u tot 5 actieve SMS-volgers aanmaken en tegelijkertijd de Companion-modus gebruiken. Om de Companion-modus te gebruiken, moet de telefoon van de gebruiker een internetverbinding hebben om gegevens te delen. Gebruikers moeten een SIM-kaart hebben om SMS-berichten vanaf hun telefoon te kunnen verzenden. Als u van een of beide opties gebruik wenst te maken, raden wij u aan om te controleren of uw mobiele bundel (voldoende) voldoet.',

    header_2:'Cyberbeveiligingsupdate (Alleen Ypsopump) 24/04/2023',
    body_2:'Het veilige gebruik van medische apps zoals de mylife CamAPS FX app is essentieel. Daarom voert Google Play Store medio mei een verplichte cybersecurity update door. Wij adviseren u om vooraf te checken of uw smartphone voldoet aan de nieuwe veiligheidseisen, zodat u de mylife CamAPS app na invoering kunt blijven gebruiken. Open het menu in de mylife CamAPS app, ga naar ‘help’ en check uw toestel voor medio mei.',

    header_1:'',
    body_1:'',
};
