/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Formation - CamAPS FX',
    titleMeta: 'Nous proposons une formation en ligne gratuite aux utilisateurs de CamAPS FX',
    descriptionMeta: 'Le programme d’éducation au diabète de Cambridge propose une formation ' +
        'certifiée en ligne gratuite pour les ' +
        'personnes atteintes de diabète de type 1, les parents et tuteurs, le personnel scolaire et les professionnels de la santé.',
    keywords:
        'camaps Formation, camaps fx Formation,  ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    trainingHeader: 'Formation certifiée',
    trainingHeader_2: 'Pour les utilisateurs, les professionnels de santé et le personnel enseignant',
    trainingHeaderText: 'Formation en ligne GRATUITE pour CamAPS FX fournie par le Programme de Formation au Diabète de Cambridge.',
    websiteLink: 'https://fr.camdiabtraining.com',
    websiteLinkHeading: 'Formation du CDEP',
    getTrained: 'Vous former',

    trainingHeaderIat: "Formation CamAPS FX",
    trainingHeaderTextIat: "Complétez la formation essentielle intégrée à l'application avant d'utiliser l'application CamAPS FX.",
    trainingSubHeaderOneIat: "Commencer",
    trainingSubTextOneIat: "Téléchargez l'application CamAPS FX. <br>Créez un compte pour commencer la formation.",

    trainingSubHeaderTwoIat: "Besoin d'aide pour installer l'application?",
    trainingParaTwoStartIat: "Consultez notre section",
    trainingParaTwoEndIat: "pour obtenir de l'aide.",
    websiteLinkHeadingSupportIat: "Soutien",

    trainingSubHeaderThreeIat: "Ressources supplémentaires",
    animationsYpsoPumpText: "Animations",
    animationsAndText: "et",
    animationsDanaText: "Animations (pompe Dana)",
    animationsFurtherText: "Apprenez grâce à des visuels étape par étape.",
    webinarsText: "Webinaires",
    webinarsFurtherText: "Regardez des sessions dirigées par des experts sur l'utilisation de l'application.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"
};
